var cgiApplicationPoll;
var cgiApplicationStopPoll = false;
var isQRLogin;
var openBankIdOnThisUnit = true;

jQuery(document).ready(function ($) {
    const UNEXPECTED_ERROR_MESSAGE = 'Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.';

    if (window.location.hash == '#poll') {
        cgiApplicationPollUser();
    }

    // Authenticate user upon log in
    $('#application-login-form').on('submit', function (e) {
        e.preventDefault();

        if (!$('#villkor').is(':checked')) {
            $('#application-login-form .cgi-message').html('Vänligen godkänn integritetspolicyn').show();
            return false;
        } else {
            $('#application-login-form .cgi-message').html('').hide();
        }

        isQRLogin = true;
        var data = {
            action: 'cgi_login_qr_user',
            security: CGIApplicationAjax.security
        };

        $('.application-login-loader').removeClass('hidden');
        $('#application-login-form .cgi-message').html('').hide();

        $.post(CGIApplicationAjax.ajaxurl, data, function (response) {
            try {
                response = jQuery.parseJSON(response);
            }
            catch {
                $('#application-login-form .cgi-message').html(UNEXPECTED_ERROR_MESSAGE).show();
                $('.application-login-loader').addClass('hidden');
                $('.application-login-loader-header').html('Inloggning startar...');
                $('.application-login-loader-instructions').html('<a class="application-abort-login" href="#">Avbryt</a>');
                $('#qr-code').html('');
                return;
            }

            if (response?.status == 'ok') {
                $('.application-login-loader .eclipse').hide();
                if (openBankIdOnThisUnit) {
                    $('.application-login-loader-header').html('Inloggning startar...');
                } else {
                    $('.application-login-loader-header').html('Starta BankID-appen och läs av QR-koden');
                }

                var qrcode = new QRCode({
                    content: response.data.qrData,
                    width: 160,
                    height: 160,
                    join: true
                });
                var svg = qrcode.svg();
                document.getElementById("qr-code").innerHTML = svg;

                cgiApplicationStopPoll = false;
                cgiApplicationPollUser();
            } else {
                try {
                    $('#application-login-form .cgi-message').html(response.error[0].message).show();
                } catch {
                    $('#application-login-form .cgi-message').html(UNEXPECTED_ERROR_MESSAGE).show();
                }
                $('.application-login-loader').addClass('hidden');
                $('.application-login-loader-header').html('Inloggning startar...');
                $('.application-login-loader-instructions').html('<a class="application-abort-login" href="#">Avbryt</a>');
                $('#qr-code').html('');
            }
        });
    });

    $('#application-login-form').on('click', '.qr-code-login', function (e) {
        e.preventDefault();
        $('#application-login-form .cgi-message').html('').hide();

        openBankIdOnThisUnit = false;
        $('#application-login-form').submit();
    });

    $('#application-login-form').on('click', '.this-unit-login', function (e) {
        e.preventDefault();
        $('#application-login-form .cgi-message').html('').hide();
        $('#application-login-form').submit();
    });

    $('#application-login-form').on('click', '.other-unit-login', function (e) {
        e.preventDefault();
        $('#application-login-form .cgi-message').html('').hide();

        openBankIdOnThisUnit = false;
        $('#application-login-form').submit();
    });

    $('#application-login-form').on('click', '.btn-abort-pnr-login', function (e) {
        e.preventDefault();
        $('#application-login-form .cgi-message').html('').hide();
        $('.hidden-ssn').hide();

        if ($(this).hasClass('is-mobile')) {
            $('#application-login-form .btn-abort-other-unit-login').show();
        }

        $('#application-login-form .qr-code-login').removeClass('hidden');
        $('#application-login-form .pnr-login').removeClass('hidden');
    });

    $('#application-login-form').on('click', '.btn-abort-other-unit-login', function (e) {
        e.preventDefault();
        $('#application-login-form .cgi-message').html('').hide();

        $('#application-login-form .btn-abort-other-unit-login').hide();

        $('#application-login-form .this-unit-login').removeClass('hidden');
        $('#application-login-form .other-unit-login').removeClass('hidden');

        $('#application-login-form .qr-code-login').addClass('hidden');
        $('#application-login-form .pnr-login').addClass('hidden');
    });

    function getQueryParam(param) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    }

    // Poll user login
    function cgiApplicationPollUser() {
        $('.application-login-loader').removeClass('hidden');
        $('#application-login-form .cgi-message').html('').hide();

        var data = {
            action: 'cgi_poll_user',
            security: CGIApplicationAjax.security,
        };

        $.ajax({
            url: CGIApplicationAjax.ajaxurl,
            data: data,
            error: function () {
                if (!cgiApplicationStopPoll) {
                    cgiApplicationPoll = setTimeout(cgiApplicationPollUser, 1000);
                }
            },
            success: function (response) {
                try {
                    response = jQuery.parseJSON(response);
                }
                catch {
                    alert('Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.');
                    window.location.assign(CGIApplicationAjax.logIn);
                    return;
                }

                $('.application-login-loader .eclipse').show();

                if (response.status == 'complete') {
                    $('.application-login-loader-header').html('BankId inloggning ok.');
                    $('.application-login-loader-instructions').html('Skickar vidare till ansökningsformuläret...');
                    $('#qr-code').html('');

                    cgiApplicationStopPoll = true;
                    const campaignCode = $('#application-campaign-code').val();
                    const applicationId = getQueryParam('application-id');

                    if (applicationId) {
                        window.location.assign(CGIApplicationAjax.applicationKyc + '?application_id=' + applicationId);
                    } else if (campaignCode == '') {
                        window.location.assign(CGIApplicationAjax.applicationCredit);
                    } else {
                        window.location.assign(CGIApplicationAjax.applicationCredit + '?campaign_code=' + campaignCode);
                    }
                } else if (response.status == 'error') {
                    try {
                        $('#application-login-form .cgi-message').html(response.error[0].message ?? UNEXPECTED_ERROR_MESSAGE).show();
                    } catch {
                        $('#application-login-form .cgi-message').html(UNEXPECTED_ERROR_MESSAGE).show();
                    }

                    $('.application-login-loader').addClass('hidden');
                    $('.application-login-loader-header').html('Inloggning startar...');
                    $('.application-login-loader-instructions').html('<a class="application-abort-login" href="#">Avbryt</a>');
                    $('#qr-code').html('');
                } else if (response.status == 'userSign') {
                    $('.application-login-loader-header').html('Nu kan du legitimera dig med BankID.');
                    $('.application-login-loader-instructions').html(response.instruction);
                    $('#qr-code').html('');
                    if (!cgiApplicationStopPoll) {
                        cgiApplicationPoll = setTimeout(cgiApplicationPollUser, 1000);
                    }
                } else if (response.status == 'outstandingTransaction' || response.status == 'noClient') {
                    if (isQRLogin) {
                        if (response.isMobile == true && openBankIdOnThisUnit == true) {
                            $('.application-login-loader-header').html(response.instruction);
                            $('.application-login-loader-instructions').html('Startar appen för Mobilt BankID...<br /><a class="application-abort-login" href="#">Avbryt</a>');
                            $('#qr-code').html('');
                            if (!cgiApplicationStopPoll) {
                                cgiApplicationStopPoll = true;
                                location.replace("bankid:///?autostarttoken=" + response.autostartToken + "&redirect=" + encodeURIComponent(CGIApplicationAjax.logIn) + "#poll");
                            }
                        } else {
                            if (!cgiApplicationStopPoll) {
                                $('.application-login-loader .eclipse').hide();
                                $('.application-login-loader-header').html('Starta BankID-appen och läs av QR-koden');

                                var qrcode = new QRCode({
                                    content: response.qrData,
                                    width: 160,
                                    height: 160,
                                    join: true //Crisp rendering and 4-5x reduced file size
                                });
                                var svg = qrcode.svg();
                                document.getElementById("qr-code").innerHTML = svg;

                                $('.application-login-loader-instructions').html('<a class="application-abort-login" href="#">Avbryt</a>');
                            }
                        }
                    } else {
                        if (response.isMobile == true) {
                            $('.application-login-loader-instructions').html('Klicka här för att<br /><a class="et_pb_button" href="bankid:///?autostarttoken=' + response.autostartToken + '&redirect=' + encodeURIComponent(CGIApplicationAjax.logIn) + '#poll">' + response.instruction + '</a><br /><a class="application-abort-login" href="#">Avbryt</a>');
                            $('#qr-code').html('');
                        } else {
                            $('.application-login-loader-instructions').html('Öppna appen för Mobilt BankID på den enhet där du har den installerad.<br /><a class="application-abort-login" href="#">Avbryt</a>');
                            $('#qr-code').html('');
                        }
                    }

                    if (!cgiApplicationStopPoll) {
                        cgiApplicationPoll = setTimeout(cgiApplicationPollUser, 1000);
                    }
                } else {
                    if (!cgiApplicationStopPoll) {
                        cgiApplicationPoll = setTimeout(cgiApplicationPollUser, 1000);
                    }
                }
            }, timeout: 20000
        });
    }

    // Abort login process
    $('.application-login-loader').on('click', '.application-abort-login', function (e) {
        e.preventDefault();

        cgiApplicationStopPoll = true;
        clearInterval(cgiApplicationPoll);

        $('.application-login-loader-header').html('Avbryter inloggning...');
        $('.application-login-loader-instructions').html('');
        $('#qr-code').html('');

        var data = {
            action: 'cgi_abort_login',
            security: CGIApplicationAjax.security
        };

        $.post(CGIApplicationAjax.ajaxurl, data, function () {
            $('.application-login-loader').addClass('hidden');
            $('.application-login-loader-header').html('Inloggning startar...');
            $('.application-login-loader-instructions').html('<a class="application-abort-login" href="#">Avbryt</a>');
            $('#qr-code').html('');
        });
    });
});
